import React from "react";
import "./Produk2.scss";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import Allogioimages from "./Allogio1.js";
import Strozziimages from "./Strozzi.js";
import Leonoraimages from "./Leonoara.js";
import Agnesiimages from "./Agnesi.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowsUpDownLeftRight } from "@fortawesome/free-solid-svg-icons";
import { faHouse } from "@fortawesome/free-solid-svg-icons";
import { faLayerGroup } from "@fortawesome/free-solid-svg-icons";
import { faBed } from "@fortawesome/free-solid-svg-icons";
import { faShower } from "@fortawesome/free-solid-svg-icons";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";


const Produk2 = () => {
  const settings = {
    dots: true,
    arrows: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
  };



  const Leonorawa = () => {
    const whatsappLink =
      "https://api.whatsapp.com/send/?phone=6285215127964&text=Halo%20Marketing%20Summarecon,%20Saya%C2%A0bisa%20minta%20detail%20Katalog,%20Simulasi%20dan%20Promo%20terbaru%20produk%20Rumah%20Leonora%20https://marketingsummaserpong.com/&type=phone_number&app_absent=0";
    window.location.href = whatsappLink;
  };
  const Strozziwa = () => {
    const whatsappLink =
      "https://api.whatsapp.com/send/?phone=6285215127964&text=Halo%20Marketing%20Summarecon,%20Saya%C2%A0bisa%20minta%20detail%20Katalog,%20Simulasi%20dan%20Promo%20terbaru%20produk%20Rumah%20Strozzi%20https://marketingsummaserpong.com/&type=phone_number&app_absent=0";
    window.location.href = whatsappLink;
  }
  const Agnesiwa = () => {  
    const whatsappLink =
      "https://api.whatsapp.com/send/?phone=6285215127964&text=Halo%20Marketing%20Summarecon,%20Saya%C2%A0bisa%20minta%20detail%20Katalog,%20Simulasi%20dan%20Promo%20terbaru%20produk%20Rumah%20Agnesi%20https://marketingsummaserpong.com/&type=phone_number&app_absent=0";
    window.location.href = whatsappLink;
  }
  const Allogiowa = () => {  
    const whatsappLink =
      "https://api.whatsapp.com/send/?phone=6285215127964&text=Halo%20Marketing%20Summarecon,%20Saya%C2%A0bisa%20minta%20detail%20Katalog,%20Simulasi%20dan%20Promo%20terbaru%20produk%20Rumah%20Allogio%20https://marketingsummaserpong.com/&type=phone_number&app_absent=0";
    window.location.href = whatsappLink;
  };
  

  return (
    <div className="container">
      <div className="containercard2">
        <div className="card">
          <Slider {...settings}>
            {Leonoraimages.map((image, index) => (
              <img
                className="imagecard"
                key={index}
                src={image}
              />
            ))}
          </Slider>
          <div className="container-judul-kartu">
            <div className="judulkartu">
              <div className="nama-rumah">Leonora</div>
              <span>Siap Huni, Free PPN</span>
            </div>
            <div className="cicilan">
              <div className="angka">13</div>
              <div className="ket">Juta/Bulan</div>
            </div>
          </div>
          <hr color="black" />
          <div className="gridspek">
            <FontAwesomeIcon color= '#334442' icon={faArrowsUpDownLeftRight} />
            <span> : 84²</span>
            <FontAwesomeIcon color= '#334442' icon={faHouse} />
            <span> : 144m²</span>
            <FontAwesomeIcon color= '#334442' icon={faBed} />
            <span>: 3 </span>
            <FontAwesomeIcon color= '#334442' icon={faShower} />
            <span>: 4 </span>
          </div>
          <div className="containerwhatsapp">
            <button onClick={Leonorawa} className="whatsapp">
              <FontAwesomeIcon icon={faWhatsapp} /> Whatsapp
            </button>
          </div>
        </div>
        <div className="card">
          <Slider {...settings}>
            {Strozziimages.map((image, index) => (
              <img
                className="imagecard"
                key={index}
                src={image}
              />
            ))}
          </Slider>
          <div className="container-judul-kartu">
            <div className="judulkartu">
              <div className="nama-rumah">Strozzi</div>
              <span>Free PPN, Full Furnish</span>
            </div>
            <div className="cicilan">
              <div className="angka">13</div>
              <div className="ket">Juta/Bulan</div>
            </div>
          </div>
          <hr color="black" />
          <div className="gridspek">
            <FontAwesomeIcon color= '#334442' icon={faArrowsUpDownLeftRight} />
            <span> : 96m²</span>
            <FontAwesomeIcon color= '#334442' icon={faHouse} />
            <span> : 161m²</span>
            <FontAwesomeIcon color= '#334442' icon={faBed} />
            <span>: 3 </span>
            <FontAwesomeIcon color= '#334442' icon={faShower} />
            <span>: 4 </span>
          </div>
          <div className="containerwhatsapp">
            <button onClick={Strozziwa} className="whatsapp">
              <FontAwesomeIcon icon={faWhatsapp} /> Whatsapp
            </button>
          </div>
        </div>
        <div className="card">
        <Slider {...settings}>
            {Agnesiimages.map((image, index) => (
              <img
                className="imagecard"
                key={index}
                src={image}
              />
            ))}
          </Slider>
          <div className="container-judul-kartu">
            <div className="judulkartu">
              <div className="nama-rumah">Agnesi</div>
              <span>Siap Huni, Full Furnish</span>
            </div>
            <div className="cicilan">
              <div className="angka">12</div>
              <div className="ket">Juta/Bulan</div>
            </div>
          </div>
          <hr color="black" />
          <div className="gridspek">
            <FontAwesomeIcon color= '#334442' icon={faArrowsUpDownLeftRight} />
            <span> : 118m²</span>
            <FontAwesomeIcon color= '#334442' icon={faHouse} />
            <span> : 90m²</span>
            <FontAwesomeIcon color= '#334442' icon={faBed} />
            <span>: 3</span>
            <FontAwesomeIcon color= '#334442' icon={faShower} />
            <span>: 2</span>
          </div>
          <div className="containerwhatsapp">
            <button onClick={Agnesiwa} className="whatsapp">
              <FontAwesomeIcon icon={faWhatsapp} /> Whatsapp
            </button>
          </div>
        </div>
        <div className="card">
          <Slider {...settings}>
            {Allogioimages.map((image, index) => (
              <img
                className="imagecard"
                key={index}
                src={image}
              />
            ))}
          </Slider>
          <div className="container-judul-kartu">
            <div className="judulkartu">
              <div className="nama-rumah">Allogio</div>
              <span>Siap Huni</span>
            </div>
            <div className="cicilan">
              <div className="angka">10</div>
              <div className="ket">Juta/Bulan</div>
            </div>
          </div>
          <hr color="black" />
          <div className="gridspek">
            <FontAwesomeIcon color= '#334442' icon={faArrowsUpDownLeftRight} />
            <span> : 70m²</span>
            <FontAwesomeIcon color= '#334442' icon={faHouse} />
            <span> : 139m²</span>
            <FontAwesomeIcon color= '#334442' icon={faLayerGroup} />
            <span>: 3</span>
            <FontAwesomeIcon color= '#334442' icon={faShower} />
            <span>: 3</span>
          </div>
          <div className="containerwhatsapp">
            <button onClick={Allogiowa} className="whatsapp">
              <FontAwesomeIcon icon={faWhatsapp} /> Whatsapp
            </button>
          </div>
        </div>
      </div>
      <div></div>
    </div>
  );
};

export default Produk2;
