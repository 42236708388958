import Carson0 from '../../../media/Residential/Carson/Carson (0).png'
import Carson1 from '../../../media/Residential/Carson/Carson (1).png'
import Carson2 from '../../../media/Residential/Carson/Carson (2).png'
import Carson3 from '../../../media/Residential/Carson/Carson (3).png'
import Carson4 from '../../../media/Residential/Carson/Carson (4).png'
import Carson5 from '../../../media/Residential/Carson/Carson (5).png'
import Carson6 from '../../../media/Residential/Carson/Carson (6).png'
import Carson7 from '../../../media/Residential/Carson/Carson (7).png'
import Carson8 from '../../../media/Residential/Carson/Carson (8).png'
import Carson9 from '../../../media/Residential/Carson/Carson (9).png'
import Carson10 from '../../../media/Residential/Carson/Carson (10).png'

const Carsonimages =[
    Carson0,Carson1,Carson2,Carson3,Carson4,Carson5,Carson6,Carson7,Carson8,Carson9,Carson10
]

export default Carsonimages;