import Mozart0 from '../../../media/Residential/Mozart/Mozart P/Mozart (0).png'
import Mozart1 from '../../../media/Residential/Mozart/Mozart P/Mozart (1).png'
import Mozart2 from '../../../media/Residential/Mozart/Mozart P/Mozart (2).png'
import Mozart3 from '../../../media/Residential/Mozart/Mozart P/Mozart (3).png'
import Mozart4 from '../../../media/Residential/Mozart/Mozart P/Mozart (4).png'
import Mozart5 from '../../../media/Residential/Mozart/Mozart P/Mozart (5).png'
import Mozart6 from '../../../media/Residential/Mozart/Mozart P/Mozart (6).png'
import Mozart7 from '../../../media/Residential/Mozart/Mozart P/Mozart (7).png'
import Mozart8 from '../../../media/Residential/Mozart/Mozart P/Mozart (8).png'
import Mozart9 from '../../../media/Residential/Mozart/Mozart P/Mozart (9).png'
import Mozart10 from '../../../media/Residential/Mozart/Mozart P/Mozart (10).png'
import Mozart11 from '../../../media/Residential/Mozart/Mozart P/Mozart (11).png'
import Mozart12 from '../../../media/Residential/Mozart/Mozart P/Mozart (12).png'
import Mozart13 from '../../../media/Residential/Mozart/Mozart P/Mozart (13).png'
import Mozart14 from '../../../media/Residential/Mozart/Mozart P/Mozart (14).png'
import Mozart15 from '../../../media/Residential/Mozart/Mozart P/Mozart (15).png'


const Mozartimages =[
    Mozart0,Mozart1,Mozart2,Mozart3,Mozart4,Mozart5,Mozart6,Mozart7,Mozart8,Mozart9,Mozart10,Mozart11,Mozart12,Mozart13,Mozart14,Mozart15
]

export default Mozartimages;