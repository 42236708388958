import Strozzi1 from '../../../media/Residential/Strozzi/Strozzi/Strozzi (1).png'
import Strozzi2 from '../../../media/Residential/Strozzi/Strozzi/Strozzi (2).png'
import Strozzi3 from '../../../media/Residential/Strozzi/Strozzi/Strozzi (3).png'
import Strozzi4 from '../../../media/Residential/Strozzi/Strozzi/Strozzi (4).png'
import Strozzi5 from '../../../media/Residential/Strozzi/Strozzi/Strozzi (5).png'
import Strozzi6 from '../../../media/Residential/Strozzi/Strozzi/Strozzi (6).png'
import Strozzi7 from '../../../media/Residential/Strozzi/Strozzi/Strozzi (7).png'
import Strozzi8 from '../../../media/Residential/Strozzi/Strozzi/Strozzi (8).png'
import Strozzi9 from '../../../media/Residential/Strozzi/Strozzi/Strozzi (9).png'
import Strozzi10 from '../../../media/Residential/Strozzi/Strozzi/Strozzi (10).png'
import Strozzi11 from '../../../media/Residential/Strozzi/Strozzi/Strozzi (11).png'
import Strozzi12 from '../../../media/Residential/Strozzi/Strozzi/Strozzi (12).png'
import Strozzi13 from '../../../media/Residential/Strozzi/Strozzi/Strozzi (13).png'

const Strozziimages =[
Strozzi1,Strozzi2,Strozzi3,Strozzi4,Strozzi5,Strozzi6,Strozzi7,Strozzi8,Strozzi9,Strozzi10,Strozzi11,Strozzi12,Strozzi13
]

export default Strozziimages;