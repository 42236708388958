import React from 'react'
import "./rumah.scss"
import Produk1 from "./Produk1/Produk1.jsx"
import Produk2 from "./Produk2/Produk2.jsx"
import Produk3 from "./Produk3/Produk3.jsx"

const rumah = () => {
  return (
    <div className='rumah'>
        <div id='rumah' className='container-header1'> RESIDENTIALS
        </div>
        <div>
            <div className='Judul'>A Home for Every Occasion</div> 
            <div className='rumah-card'>
            <Produk1/>
            <Produk2/>
            <Produk3/>
            </div>
        </div>
    </div>
  )
}

export default rumah;