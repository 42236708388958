import React from 'react'
import "./ruko.scss"
import Ruko1 from "./Ruko/ruko1"

const ruko = () => {
  return (
    <div id='ruko' className='ruko'>
        <div className='container-header1'> Ruko Commercials
        </div>
        <div>
            <div className='Judul'></div> 
            <Ruko1/>
        </div>
    </div>
  )
}

export default ruko;