import React from 'react'
import './header.scss'
import Logo from '../../media/Logo.png'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons'


const header = () => {

    const waheader = () => {
        const whatsappLink =
          "https://api.whatsapp.com/send/?phone=6285215127964&text=Halo%20Marketing%20Summarecon,%20Saya%20bisa%20minta%20detail%20Katalog,%20Simulasi,%20dan%20Promo%20terbaru%20Summarecon%20https://marketingsummaserpong//&type=phone_number&app_absent=0";
        window.location.href = whatsappLink;
    }
  return (
    <div className='container-header'>
        <div className='logo'>
            <img className='gambar' src={Logo} alt="logo summarecon serpong" />
        </div>
        <div className='container-button'>
            <button className= 'button' onClick={waheader}>
            <FontAwesomeIcon icon={faWhatsapp} /> Whatsapp
                </button>
        </div>
    </div>
  )
}

export default header;