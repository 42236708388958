import React from "react";
import "./home.scss"
import Header from '../../section/Header/header'
import Banner from "../../section/Banner/Banner.jsx";
import Fitur from "../../section/fitur/fitur.jsx";
import About from "../../section/about/about.jsx"
import Launching from "../../section/Launching/launching.jsx"
import Promo from "../../section/promo/promo.jsx"
import Fasilitas from "../../section/fasilitas/fasilitas.jsx";
import Lokasi from "../../section/Lokasi/lokasi.jsx";
import Footer from "../../section/Footer/footer.jsx";
import Rumah from "../../section/Rumah/rumah.jsx"
import Apartemen from "../../section/Apartemen/apartemen.jsx"
import Ruko from "../../section/Ruko/ruko.jsx"
import Launchingcarson from "../../section/Launchingcarson/launchingcarson.jsx";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ScrollToHashElement from "./ScrollToHashElement";
import Tombol from "../../media/tombol.webp"


const home = () => {
    const fungsiganteng = () => {
        const whatsappLink =
          "https://api.whatsapp.com/send/?phone=6285215127964&text=Halo%20Marketing%20Summarecon,%20Saya%C2%A0bisa%20minta%20detail%20Katalog,%20Simulasi,%20dan%20Promo%20terbaru%20Summarecon%20https://marketingsummaserpong.com//&type=phone_number&app_absent=0//";
        window.location.href = whatsappLink;
      };
    return (
        <div className="home">
        <ScrollToHashElement />  
        <Header />
        <Banner />
        <Fitur />
        <About />
        <Launching />
        <Launchingcarson />
        <Promo />
        <Fasilitas />
        <Rumah />
        <Apartemen />
        <Ruko />
        <Lokasi />
        <Footer />
        <img
        onClick={fungsiganteng}
        src={Tombol}
        className="tombolwa"
        alt="Hubungi Sekarang!"
      />
      <div className="wamobile">
        <button onClick={fungsiganteng} className="mobile">
          <div>
            <FontAwesomeIcon icon={faWhatsapp} /> Whatsapp Sekarang
          </div>
        </button>
      </div>
        

        </div>
    );
};

export default home;


