import Downtown1 from '../../../media/Ruko/Downtown/Downtown (1).png'
import Downtown2 from '../../../media/Ruko/Downtown/Downtown (2).png'
import Downtown3 from '../../../media/Ruko/Downtown/Downtown (3).png'
import Downtown4 from '../../../media/Ruko/Downtown/Downtown (4).png'
import Downtown5 from '../../../media/Ruko/Downtown/Downtown (5).png'
import Downtown6 from '../../../media/Ruko/Downtown/Downtown (6).png'
import Downtown7 from '../../../media/Ruko/Downtown/Downtown (7).png'
import Downtown8 from '../../../media/Ruko/Downtown/Downtown (8).png'

const Downtownimages =[
Downtown1,Downtown2,Downtown3,Downtown4,Downtown5,Downtown6,Downtown7,Downtown8
]

export default Downtownimages;