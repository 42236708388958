import Heron0 from '../../../media/Residential/Heron/Heron/Heron/Heron  (0).png'
import Heron1 from '../../../media/Residential/Heron/Heron/Heron/Heron  (1).png'
import Heron2 from '../../../media/Residential/Heron/Heron/Heron/Heron  (2).png'
import Heron3 from '../../../media/Residential/Heron/Heron/Heron/Heron  (3).png'
import Heron4 from '../../../media/Residential/Heron/Heron/Heron/Heron  (4).png'
import Heron5 from '../../../media/Residential/Heron/Heron/Heron/Heron  (5).png'
import Heron6 from '../../../media/Residential/Heron/Heron/Heron/Heron  (6).png'
import Heron7 from '../../../media/Residential/Heron/Heron/Heron/Heron  (7).png'
import Heron8 from '../../../media/Residential/Heron/Heron/Heron/Heron  (8).png'
import Heron9 from '../../../media/Residential/Heron/Heron/Heron/Heron  (9).png'
import Heron10 from '../../../media/Residential/Heron/Heron/Heron/Heron  (10).png'
import Heron11 from '../../../media/Residential/Heron/Heron/Heron/Heron  (11).png'
import Heron12 from '../../../media/Residential/Heron/Heron/Heron/Heron  (12).png'
import Heron13 from '../../../media/Residential/Heron/Heron/Heron/Heron  (13).png'
import Heron14 from '../../../media/Residential/Heron/Heron/Heron/Heron  (14).png'
import Heron15 from '../../../media/Residential/Heron/Heron/Heron/Heron  (15).png'
import Heron16 from '../../../media/Residential/Heron/Heron/Heron/Heron  (16).png'
import Heron17 from '../../../media/Residential/Heron/Heron/Heron/Heron  (17).png'


const Heronimages =[
    Heron0,Heron1,Heron2,Heron3,Heron4,Heron5,Heron6,Heron7,Heron8,Heron9,Heron10,Heron11,Heron12,Heron13,Heron14,Heron15,Heron16,Heron17
]

export default Heronimages;