import Fasilitas1 from '../../media/Fasilitas Cluster/Fasilitas (1).png'
import Fasilitas2 from '../../media/Fasilitas Cluster/Fasilitas (2).png'
import Fasilitas3 from '../../media/Fasilitas Cluster/Fasilitas (3).png'
import Fasilitas4 from '../../media/Fasilitas Cluster/Fasilitas (4).png'
import Fasilitas5 from '../../media/Fasilitas Cluster/Fasilitas (5).png'
import Fasilitas6 from '../../media/Fasilitas Cluster/Fasilitas (6).png'
import Fasilitas7 from '../../media/Fasilitas Cluster/Fasilitas (7).png'
import Fasilitas8 from '../../media/Fasilitas Cluster/Fasilitas (8).png'
import Fasilitas9 from '../../media/Fasilitas Cluster/Fasilitas (9).png'
import Fasilitas10 from '../../media/Fasilitas Cluster/Fasilitas (10).png'


const fasilitasimages =[
    Fasilitas1,Fasilitas2,Fasilitas3,Fasilitas4,Fasilitas5,Fasilitas6,Fasilitas7,Fasilitas8,Fasilitas9,Fasilitas10
]

export default fasilitasimages;