import MtownS1 from '../../../media/Apartemen Sumser/MTown Signature/Mtown Signature (1).png'
import MtownS2 from '../../../media/Apartemen Sumser/MTown Signature/Mtown Signature (2).png'
import MtownS3 from '../../../media/Apartemen Sumser/MTown Signature/Mtown Signature (3).png'
import MtownS4 from '../../../media/Apartemen Sumser/MTown Signature/Mtown Signature (4).png'
import MtownS5 from '../../../media/Apartemen Sumser/MTown Signature/Mtown Signature (5).png'

const MtownSimages =[
MtownS1,MtownS2,MtownS3,MtownS4,MtownS5
]

export default MtownSimages;