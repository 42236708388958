import Baroni1 from '../../../media/Residential/Baroni/Baroni (1).png'
import Baroni2 from '../../../media/Residential/Baroni/Baroni (2).png'
import Baroni3 from '../../../media/Residential/Baroni/Baroni (3).png'
import Baroni4 from '../../../media/Residential/Baroni/Baroni (4).png'
import Baroni5 from '../../../media/Residential/Baroni/Baroni (5).png'
import Baroni6 from '../../../media/Residential/Baroni/Baroni (6).png'
import Baroni7 from '../../../media/Residential/Baroni/Baroni (7).png'
import Baroni8 from '../../../media/Residential/Baroni/Baroni (8).png'
import Baroni9 from '../../../media/Residential/Baroni/Baroni (9).png'

const Baroniimages =[
Baroni1,Baroni2,Baroni3,Baroni4,Baroni5,Baroni6,Baroni7,Baroni8,Baroni9
]

export default Baroniimages;