import Ardea1 from '../../../media/Residential/Ardea/New Heron 11X19 P/Ardea (1).png'
import Ardea2 from '../../../media/Residential/Ardea/New Heron 11X19 P/Ardea (2).png'
import Ardea3 from '../../../media/Residential/Ardea/New Heron 11X19 P/Ardea (3).png'
import Ardea4 from '../../../media/Residential/Ardea/New Heron 11X19 P/Ardea (4).png'
import Ardea5 from '../../../media/Residential/Ardea/New Heron 11X19 P/Ardea (5).png'
import Ardea6 from '../../../media/Residential/Ardea/New Heron 11X19 P/Ardea (6).png'
import Ardea7 from '../../../media/Residential/Ardea/New Heron 11X19 P/Ardea (7).png'
import Ardea8 from '../../../media/Residential/Ardea/New Heron 11X19 P/Ardea (8).png'
import Ardea9 from '../../../media/Residential/Ardea/New Heron 11X19 P/Ardea (9).png'
import Ardea10 from '../../../media/Residential/Ardea/New Heron 11X19 P/Ardea (10).png'
import Ardea11 from '../../../media/Residential/Ardea/New Heron 11X19 P/Ardea (11).png'
import Ardea12 from '../../../media/Residential/Ardea/New Heron 11X19 P/Ardea (12).png'

const Ardeaimages =[
Ardea1,Ardea2,Ardea3,Ardea4,Ardea5,Ardea6,Ardea7,Ardea8,Ardea9,Ardea10,Ardea11,Ardea12
]

export default Ardeaimages;