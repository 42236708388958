import Rainbow1 from '../../../media/Apartemen Sumser/Rainbow Spring/Rainbow Spring (1).png'
import Rainbow2 from '../../../media/Apartemen Sumser/Rainbow Spring/Rainbow Spring (2).png'
import Rainbow3 from '../../../media/Apartemen Sumser/Rainbow Spring/Rainbow Spring (3).png'
import Rainbow4 from '../../../media/Apartemen Sumser/Rainbow Spring/Rainbow Spring (4).png'
import Rainbow5 from '../../../media/Apartemen Sumser/Rainbow Spring/Rainbow Spring (5).png'
import Rainbow6 from '../../../media/Apartemen Sumser/Rainbow Spring/Rainbow Spring (6).png'
import Rainbow7 from '../../../media/Apartemen Sumser/Rainbow Spring/Rainbow Spring (7).png'
import Rainbow8 from '../../../media/Apartemen Sumser/Rainbow Spring/Rainbow Spring (8).png'
import Rainbow9 from '../../../media/Apartemen Sumser/Rainbow Spring/Rainbow Spring (9).png'
import Rainbow10 from '../../../media/Apartemen Sumser/Rainbow Spring/Rainbow Spring (10).png'
import Rainbow11 from '../../../media/Apartemen Sumser/Rainbow Spring/Rainbow Spring (11).png'
import Rainbow12 from '../../../media/Apartemen Sumser/Rainbow Spring/Rainbow Spring (12).png'

const Rainbowimages =[
Rainbow1,Rainbow2,Rainbow3,Rainbow4,Rainbow5,Rainbow6,Rainbow7,Rainbow8,Rainbow9,Rainbow10,Rainbow11,Rainbow12
]

export default Rainbowimages;