import React from 'react'
import "./lokasi1.scss"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckDouble } from '@fortawesome/free-solid-svg-icons';
import Mapsimage from "../../media/lokasi.png"

const lokasi = () => {
  return (
        <div className='container-maps1'>
        <div id='lokasi' className='judul1'>
            LOKASI
        </div>
        <div className='google-maps1'>
        </div>
        <div className='feature1'>
            <div className='feature-list11'>
                    <div className='surroundingarea'> 
                    <FontAwesomeIcon  icon={faCheckDouble } />
                    <span style={{color:'black'}}>&nbsp;&nbsp;7 Menit ke Prastya Mulya University</span>
                    </div>
                    <div className='surroundingarea'>
                    <FontAwesomeIcon icon={faCheckDouble} />
                    <span style={{color:'black'}}>&nbsp;&nbsp;10 Menit ke Atma Jaya University</span>
                    </div>
                    <div className='surroundingarea'>
                    <FontAwesomeIcon icon={faCheckDouble} />
                    <span style={{color:'black'}}>&nbsp;&nbsp;10 Menit ke Binus University</span>
                    </div>
                    <div className='surroundingarea'>
                    <FontAwesomeIcon icon={faCheckDouble} />
                    <span style={{color:'black'}}>&nbsp;&nbsp;10 Menit ke Al-Azhar BSD School</span>
                    </div>
                    <div className='surroundingarea'>
                    <FontAwesomeIcon icon={faCheckDouble} />
                    <span style={{color:'black'}}>&nbsp;&nbsp;10 Menit ke Pelita Harapan University</span>
                    </div>
            </div>
            <div className='feature-list11'>
                    <div className='surroundingarea'> 
                    <FontAwesomeIcon icon={faCheckDouble } />
                    <span style={{color:'black'}}>&nbsp;&nbsp;5 Menit ke Mitra Keluarga Hospital</span>
                    </div>
                    <div className='surroundingarea'>
                    <FontAwesomeIcon icon={faCheckDouble} />
                    <span style={{color:'black'}}>&nbsp;&nbsp;10 Menit ke Eka Hospital</span>
                    </div>
                    <div className='surroundingarea'>
                    <FontAwesomeIcon icon={faCheckDouble} />
                    <span style={{color:'black'}}>&nbsp;&nbsp;10 Menit ke Columbia Asia BSD</span>
                    </div>
                    <div className='surroundingarea'>
                    <FontAwesomeIcon icon={faCheckDouble} />
                    <span style={{color:'black'}}>&nbsp;&nbsp;15 Menit ke Mayapada Hospital</span>
                    </div>
                    <div className='surroundingarea'>
                    <FontAwesomeIcon icon={faCheckDouble} />
                    <span style={{color:'black'}}>&nbsp;&nbsp;15 Menit ke RS Hermina Serpong</span>
                    </div>
                </div>
                <div className='feature-list11'>
                <div className='surroundingarea'> 
                    <FontAwesomeIcon icon={faCheckDouble } />
                    <span style={{color:'black'}}>&nbsp;&nbsp;10 Menit ke Ocean Park BSD</span>
                    </div>
                    <div className='surroundingarea'>
                    <FontAwesomeIcon icon={faCheckDouble} />
                    <span style={{color:'black'}}>&nbsp;&nbsp;10 Menit ke Living World</span>
                    </div>
                    <div className='surroundingarea'>
                    <FontAwesomeIcon icon={faCheckDouble} />
                    <span style={{color:'black'}}>&nbsp;&nbsp;10 Menit ke The Breeze BSD</span>
                    </div>
                    <div className='surroundingarea'>
                    <FontAwesomeIcon icon={faCheckDouble} />
                    <span style={{color:'black'}}>&nbsp;&nbsp;10 Menit ke Teras Kota</span>
                    </div>
                    <div className='surroundingarea'>
                    <FontAwesomeIcon icon={faCheckDouble} />
                    <span style={{color:'black'}}>&nbsp;&nbsp;10 Menit ke Taman Kota</span>
                    </div>
                </div>
        </div>
    </div>
  )
}

export default lokasi;