import React from 'react'
import "./launching.scss"
import hardsellimage from "../../media/hardsell-sms.png"
import background from "../../media/background-sms.webp"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckDouble } from "@fortawesome/free-solid-svg-icons";


const launching = () => {
    const handleConsultationClick = () => {
        const whatsappLink =
          "https://api.whatsapp.com/send/?phone=6285215127964&text=Halo%20Marketing%20Summarecon,%20Saya%C2%A0bisa%20minta%20detail%20Promo%20terbaru%20Summarecon%20https://marketingsummaserpong.com//&type=phone_number&app_absent=0";
        window.location.href = whatsappLink;
    }
  return (
    <div className="container-launching">
         <div id='launching' className="hardsell">
            <div className="judul-hardsell1">
                <h1>New Launching</h1>
                <h2>Ardea</h2>
            </div>
             <img className="hardsell-sms" src={hardsellimage} alt="hardsell-summarecon-serpong" />
        </div>
        <div className="container-promo">
            <div className="judul-hardsell">
                <h1>New Launching</h1>
                <h2>Ardea</h2>
            </div>
            <hr />
            <div className="judul-promo-list">
                <h1>Promo New Launching</h1>
                <div className="list-promo">
                    <div className="List promo1">
                        <div className="penawaranpoin">
                            <FontAwesomeIcon icon={faCheckDouble} />
                            <span style={{ color: "white" }}>&nbsp;&nbsp;Free Lift</span>
                        </div>
                        <div className="penawaranpoin">
                            <FontAwesomeIcon icon={faCheckDouble} />
                            <span style={{ color: "white" }}>&nbsp;&nbsp;Free Smart Panel</span>
                        </div>
                        <div className="penawaranpoin">
                            <FontAwesomeIcon icon={faCheckDouble} />
                            <span style={{ color: "white" }}>&nbsp;&nbsp;Free Smart Door Lock</span>
                        </div>
                        <div className="penawaranpoin">
                            <FontAwesomeIcon icon={faCheckDouble} />
                            <span style={{ color: "white" }}>&nbsp;&nbsp;Free Smart Switch</span>
                        </div>
                    </div>
                    <div className="List Promo2">
                    <div className="penawaranpoin">
                            <FontAwesomeIcon icon={faCheckDouble} />
                            <span style={{ color: "white" }}>&nbsp;&nbsp;Free Motion Detector</span>
                        </div>
                        <div className="penawaranpoin">
                            <FontAwesomeIcon icon={faCheckDouble} />
                            <span style={{ color: "white" }}>&nbsp;&nbsp;Free CCTV</span>
                        </div>
                        <div className="penawaranpoin">
                            <FontAwesomeIcon icon={faCheckDouble} />
                            <span style={{ color: "white" }}>&nbsp;&nbsp;Free Sanitary Kohler</span>
                        </div>
                        <div className="penawaranpoin">
                            <FontAwesomeIcon icon={faCheckDouble} />
                            <span style={{ color: "white" }}>&nbsp;&nbsp;Free Smart Socket</span>
                        </div>
                    </div> 
                </div>
                <div className="disclaimer">
                    <h1>*Disclaimer</h1>
                </div>
                <div className="button-penawaran"><button className="buttonpenawaran" onClick={handleConsultationClick}>
                     Request Consultation
                </button>
                </div>
            </div>
        </div>
    </div>
  )
}

export default launching;