import Agnesi1 from '../../../media/Residential/Agnesi/Agnesi (1).png'
import Agnesi2 from '../../../media/Residential/Agnesi/Agnesi (2).png'
import Agnesi3 from '../../../media/Residential/Agnesi/Agnesi (3).png'
import Agnesi4 from '../../../media/Residential/Agnesi/Agnesi (4).png'
import Agnesi5 from '../../../media/Residential/Agnesi/Agnesi (5).png'
import Agnesi6 from '../../../media/Residential/Agnesi/Agnesi (6).png'
import Agnesi7 from '../../../media/Residential/Agnesi/Agnesi (7).png'
import Agnesi8 from '../../../media/Residential/Agnesi/Agnesi (8).png'
import Agnesi9 from '../../../media/Residential/Agnesi/Agnesi (9).png'
import Agnesi10 from '../../../media/Residential/Agnesi/Agnesi (10).png'
import Agnesi11 from '../../../media/Residential/Agnesi/Agnesi (11).png'
import Agnesi12 from '../../../media/Residential/Agnesi/Agnesi (12).png'

const Agnesiimages =[
Agnesi1,Agnesi2,Agnesi3,Agnesi4,Agnesi5,Agnesi6,Agnesi7,Agnesi8,Agnesi9,Agnesi10,Agnesi11,Agnesi12
]

export default Agnesiimages;