import Vivaldi1 from '../../../media/Residential/Vivaldi/Vivaldi (1).png'
import Vivaldi2 from '../../../media/Residential/Vivaldi/Vivaldi (2).png'
import Vivaldi3 from '../../../media/Residential/Vivaldi/Vivaldi (3).png'
import Vivaldi4 from '../../../media/Residential/Vivaldi/Vivaldi (4).png'
import Vivaldi5 from '../../../media/Residential/Vivaldi/Vivaldi (5).png'
import Vivaldi6 from '../../../media/Residential/Vivaldi/Vivaldi (6).png'
import Vivaldi7 from '../../../media/Residential/Vivaldi/Vivaldi (7).png'
import Vivaldi8 from '../../../media/Residential/Vivaldi/Vivaldi (8).png'
import Vivaldi9 from '../../../media/Residential/Vivaldi/Vivaldi (9).png'

const Vivaldiimages =[
Vivaldi1,Vivaldi2,Vivaldi3,Vivaldi4,Vivaldi5,Vivaldi6,Vivaldi7,Vivaldi8,Vivaldi9
]

export default Vivaldiimages;