import React from 'react'
import "./fitur.scss"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBuilding, faHouseUser, faPersonSwimming } from "@fortawesome/free-solid-svg-icons";


const fitur = () => {
     return (
        <div className="feature">
        <div className="icon">
          <div className="security">
            <FontAwesomeIcon color="#334442" size="3x" icon={faHouseUser} />
            <div className="des-security"> KPR Instan Approval </div>
          </div>
          <div className="security">
            <FontAwesomeIcon color="#334442" size="3x" icon={faPersonSwimming} />
            <div className="des-security"> Fasilitas Premium </div>
          </div>
          <div className="security">
            <FontAwesomeIcon color="#334442" size="3x" icon={faBuilding} />
            <div className="des-security"> Kawasan Ekslusif </div>
          </div>
        </div>
      </div>
  )
}

export default fitur;


