import Leonora1 from '../../../media/Residential/Leonora/Leonora (1).png'
import Leonora2 from '../../../media/Residential/Leonora/Leonora (2).png'
import Leonora3 from '../../../media/Residential/Leonora/Leonora (3).png'
import Leonora4 from '../../../media/Residential/Leonora/Leonora (4).png'
import Leonora5 from '../../../media/Residential/Leonora/Leonora (5).png'
import Leonora6 from '../../../media/Residential/Leonora/Leonora (6).png'
import Leonora7 from '../../../media/Residential/Leonora/Leonora (7).png'

const Leonoraimages =[
Leonora1,Leonora2,Leonora3,Leonora4,Leonora5,Leonora6,Leonora7
]

export default Leonoraimages;