import React from "react";
import "./apartemen1.scss";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import Ardeaimages from "./Ardea.js";
import MtownRimages from "./Mtownresidence.js";
import Rainbowimages from "./Rainbow.js";
import MtownSimages from "./Mtownsignature.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowsUpDownLeftRight } from "@fortawesome/free-solid-svg-icons";
import { faHouse } from "@fortawesome/free-solid-svg-icons";
import { faBed } from "@fortawesome/free-solid-svg-icons";
import { faShower } from "@fortawesome/free-solid-svg-icons";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";


const Produk1 = () => {
  const settings = {
    dots: true,
    arrows: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
  };



  const whatsappMtownResidence = () => {
    const whatsappLink =
      "https://api.whatsapp.com/send/?phone=6285215127964&text=Halo%20Marketing%20Summarecon,%20Saya%C2%A0bisa%20minta%20detail%20Katalog,%20Simulasi%20dan%20Promo%20terbaru%20produk%20Rumah%20M-Town%20Residence%20https://marketingsummaserpong.com/&type=phone_number&app_absent=0";
    window.location.href = whatsappLink;
  }
  const whatsappMtownSignature = () => {  
    const whatsappLink =
      "https://api.whatsapp.com/send/?phone=6285215127964&text=Halo%20Marketing%20Summarecon,%20Saya%C2%A0bisa%20minta%20detail%20Katalog,%20Simulasi%20dan%20Promo%20terbaru%20produk%20Rumah%20M-Town%20Signature%20https://marketingsummaserpong.com/&type=phone_number&app_absent=0";
    window.location.href = whatsappLink;
  }
  const whatsappRainbow = () => {  
    const whatsappLink =
      "https://api.whatsapp.com/send/?phone=6285215127964&text=Halo%20Marketing%20Summarecon,%20Saya%C2%A0bisa%20minta%20detail%20Katalog,%20Simulasi%20dan%20Promo%20terbaru%20produk%20Rumah%20Rainbow%20Springs%20https://marketingsummaserpong.com/&type=phone_number&app_absent=0";
    window.location.href = whatsappLink;
  };
  

  return (
    <div className="container">
      <div className="containercard-apart">
        <div className="card">
          <Slider {...settings}>
            {MtownRimages.map((image, index) => (
              <img
                className="imagecard"
                key={index}
                src={image}
              />
            ))}
          </Slider>
          <div className="container-judul-kartu">
            <div className="judulkartu">
              <div className="nama-rumah">M-Town Residence</div>
              <span>Siap Huni, Free PPN</span>
            </div>
            <div className="cicilan">
              <div className="angka">3</div>
              <div className="ket">Juta/Bulan</div>
            </div>
          </div>
          <hr color="black" />
          <div className="gridspek">
            <FontAwesomeIcon color= '#334442' icon={faArrowsUpDownLeftRight} />
            <span> : 45²</span>
            <FontAwesomeIcon color= '#334442' icon={faBed} />
            <span>: 2</span>
            <FontAwesomeIcon color= '#334442' icon={faShower} />
            <span>: 1</span>
          </div>
          <div className="containerwhatsapp">
            <button onClick={whatsappMtownResidence} className="whatsapp">
              <FontAwesomeIcon icon={faWhatsapp} /> Whatsapp
            </button>
          </div>
        </div>
        <div className="card">
          <Slider {...settings}>
            {Rainbowimages.map((image, index) => (
              <img
                className="imagecard"
                key={index}
                src={image}
              />
            ))}
          </Slider>
          <div className="container-judul-kartu">
            <div className="judulkartu">
              <div className="nama-rumah">Rainbow Spring</div>
              <span>Siap Huni</span>
            </div>
            <div className="cicilan">
              <div className="angka">8</div>
              <div className="ket">Juta/Bulan</div>
            </div>
          </div>
          <hr color="black" />
          <div className="gridspek">
            <FontAwesomeIcon color= '#334442' icon={faArrowsUpDownLeftRight} />
            <span> : 125m²</span>
            <FontAwesomeIcon color= '#334442' icon={faBed} />
            <span>: 4+1</span>
            <FontAwesomeIcon color= '#334442' icon={faShower} />
            <span>: 2</span>
          </div>
          <div className="containerwhatsapp">
            <button onClick={whatsappRainbow} className="whatsapp">
              <FontAwesomeIcon icon={faWhatsapp} /> Whatsapp
            </button>
          </div>
        </div>
        <div className="card">
        <Slider {...settings}>
            {MtownSimages.map((image, index) => (
              <img
                className="imagecard"
                key={index}
                src={image}
              />
            ))}
          </Slider>
          <div className="container-judul-kartu">
            <div className="judulkartu">
              <div className="nama-rumah">M-Town Signature</div>
              <span>Siap Huni, Free PPN</span>
            </div>
            <div className="cicilan">
              <div className="angka">9</div>
              <div className="ket">Juta/Bulan</div>
            </div>
          </div>
          <hr color="black" />
          <div className="gridspek">
            <FontAwesomeIcon color= '#334442' icon={faArrowsUpDownLeftRight} />
            <span> : 91m²</span>
            <FontAwesomeIcon color= '#334442' icon={faBed} />
            <span>: 3</span>
            <FontAwesomeIcon color= '#334442' icon={faShower} />
            <span>: 1</span>
          </div>
          <div className="containerwhatsapp">
            <button onClick={whatsappMtownSignature} className="whatsapp">
              <FontAwesomeIcon icon={faWhatsapp} /> Whatsapp
            </button>
          </div>
        </div>
      </div>
      <div></div>
    </div>
  );
};

export default Produk1;
