import React from "react";
import "./about.scss"
import logo from "../../media/Logo.png"

const about = () => {
    return (
        <div className="container-about">
                <div className="logo-about">
                    <img className="gambar-logo" src={logo} alt="logo-summarecon-serpong" />
                </div>
            <div className="container-deskripsi">
                <div className="deskripsi-about">
                Summarecong Serpong merupakan sebuah wilayah yang terletak di Klapa Dua, Kabupaten Tangerang, Provinsi Banten. Summarecon Serpong merupakan pengembang apartemen, perkantoran, gedung komersial, apartemen dan pusat bisnis lainnya di kawasan Gading Serpong. Kawasan residensial ini merupakan kawasan residensial dan komersial yang dikembangkan di atas lahan seluas kurang lebih 320 hektar, meliputi 12.000 unit hunian, 6.000 unit hunian, 1.800 kavling hunian, dan lebih dari 2.000 bangunan komersial. Selain itu, Summarecon Serpong menawarkan berbagai fasilitas pendidikan dan rekreasi, serta pusat perbelanjaan seperti Summarecon Mall Serpong.
                </div>
            </div>
        </div>
    );
};

export default about;