import Allogio1 from '../../../media/Residential/Allogio/Allogio/Allogio (1).png'
import Allogio2 from '../../../media/Residential/Allogio/Allogio/Allogio (2).png'
import Allogio3 from '../../../media/Residential/Allogio/Allogio/Allogio (3).png'
import Allogio4 from '../../../media/Residential/Allogio/Allogio/Allogio (4).png'
import Allogio5 from '../../../media/Residential/Allogio/Allogio/Allogio (5).png'
import Allogio6 from '../../../media/Residential/Allogio/Allogio/Allogio (6).png'
import Allogio7 from '../../../media/Residential/Allogio/Allogio/Allogio (7).png'
import Allogio8 from '../../../media/Residential/Allogio/Allogio/Allogio (8).png'
import Allogio9 from '../../../media/Residential/Allogio/Allogio/Allogio (9).png'
import Allogio10 from '../../../media/Residential/Allogio/Allogio/Allogio (10).png'
import Allogio11 from '../../../media/Residential/Allogio/Allogio/Allogio (11).png'
import Allogio12 from '../../../media/Residential/Allogio/Allogio/Allogio (12).png'
import Allogio13 from '../../../media/Residential/Allogio/Allogio/Allogio (13).png'

const Allogioimages =[
Allogio1,Allogio2,Allogio3,Allogio4,Allogio5,Allogio6,Allogio7,Allogio8,Allogio9,Allogio10,Allogio11,Allogio12,Allogio13
]

export default Allogioimages;