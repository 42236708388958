import React from 'react'
import "./Banner.scss"



const banner = () => {
     return (
    <div className='background-container'>
    </div>
  )
}

export default banner;