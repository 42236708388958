import React from "react";
import "./ruko1.scss";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import Edisonimages from "./Edison.js";
import Downtownimages from "./Downtown.js";
import Carsonimages from "./Carson.js";
import Melodyimages from "./Melody.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowsUpDownLeftRight, faLayerGroup } from "@fortawesome/free-solid-svg-icons";
import { faHouse } from "@fortawesome/free-solid-svg-icons";
import { faBed } from "@fortawesome/free-solid-svg-icons";
import { faShower } from "@fortawesome/free-solid-svg-icons";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";

const ruko1 = () => {
  const settings = {
    dots: true,
    arrows: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
  };



  const EdisonWa = () => {
    const whatsappLink =
      "https://api.whatsapp.com/send/?phone=6285215127964&text=Halo%20Marketing%20Summarecon,%20Saya%C2%A0bisa%20minta%20detail%20Katalog,%20Simulasi%20dan%20Promo%20terbaru%20produk%20Ruko%20Edison%20https://marketingsummaserpong/&type=phone_number&app_absent=0";
    window.location.href = whatsappLink;
  };
  const DowntownWa = () => {
    const whatsappLink =
      "https://api.whatsapp.com/send/?phone=6285215127964&text=Halo%20Marketing%20Summarecon,%20Saya%C2%A0bisa%20minta%20detail%20Katalog,%20Simulasi%20dan%20Promo%20terbaru%20produk%20Ruko%20Downtown%20Drive%20https://marketingsummaserpong/&type=phone_number&app_absent=0";
    window.location.href = whatsappLink;
  }
  const MelodyWa = () => {  
    const whatsappLink =
      "https://api.whatsapp.com/send/?phone=6285215127964&text=Halo%20Marketing%20Summarecon,%20Saya%C2%A0bisa%20minta%20detail%20Katalog,%20Simulasi%20dan%20Promo%20terbaru%20produk%20Ruko%20Melody%20https://marketingsummaserpong/&type=phone_number&app_absent=0";
    window.location.href = whatsappLink;
  }
  const CarsonWa = () => {  
    const whatsappLink =
      "https://api.whatsapp.com/send/?phone=6285215127964&text=Halo%20Marketing%20Summarecon,%20Saya%C2%A0bisa%20minta%20detail%20Katalog,%20Simulasi%20dan%20Promo%20terbaru%20produk%20Ruko%20Carson%20https://marketingsummaserpong.com/&type=phone_number&app_absent=0";
    window.location.href = whatsappLink;
  };
  

  return (
    <div className="container">
      <div className="containercard-ruko">
        <div className="card">
          <Slider {...settings}>
            {Edisonimages.map((image, index) => (
              <img
                className="imagecard"
                key={index}
                src={image}
              />
            ))}
          </Slider>
          <div className="container-judul-kartu">
            <div className="judulkartu">
              <div className="nama-rumah">Edison</div>
              <span>Ruko 3 lantai</span>
            </div>
            <div className="cicilan">
              <div className="angka">10</div>
              <div className="ket">Juta/Bulan</div>
            </div>
          </div>
          <hr color="black" />
          <div className="gridspek">
            <FontAwesomeIcon color= '#334442' icon={faArrowsUpDownLeftRight} />
            <span> : 77,5²</span>
            <FontAwesomeIcon color= '#334442' icon={faHouse} />
            <span> : 110m²</span>
            <FontAwesomeIcon color= '#334442' icon={faLayerGroup} />
            <span>: 3</span>
            <FontAwesomeIcon color= '#334442' icon={faShower} />
            <span>: 2</span>
          </div>
          <div className="containerwhatsapp">
            <button onClick={EdisonWa} className="whatsapp">
              <FontAwesomeIcon icon={faWhatsapp} /> Whatsapp
            </button>
          </div>
        </div>
        <div className="card">
          <Slider {...settings}>
            {Downtownimages.map((image, index) => (
              <img
                className="imagecard"
                key={index}
                src={image}
              />
            ))}
          </Slider>
          <div className="container-judul-kartu">
            <div className="judulkartu">
              <div className="nama-rumah">Downtown</div>
              <span>Ruko 3 Lantai</span>
            </div>
            <div className="cicilan">
              <div className="angka">11</div>
              <div className="ket">Juta/Bulan</div>
            </div>
          </div>
          <hr color="black" />
          <div className="gridspek">
            <FontAwesomeIcon color= '#334442' icon={faArrowsUpDownLeftRight} />
            <span> : 84m²</span>
            <FontAwesomeIcon color= '#334442' icon={faHouse} />
            <span> : 101m²</span>
            <FontAwesomeIcon color= '#334442' icon={faLayerGroup} />
            <span>: 3</span>
            <FontAwesomeIcon color= '#334442' icon={faShower} />
            <span>: 3</span>
          </div>
          <div className="containerwhatsapp">
            <button onClick={DowntownWa} className="whatsapp">
              <FontAwesomeIcon icon={faWhatsapp} /> Whatsapp
            </button>
          </div>
        </div>
        <div className="card">
        <Slider {...settings}>
            {Melodyimages.map((image, index) => (
              <img
                className="imagecard"
                key={index}
                src={image}
              />
            ))}
          </Slider>
          <div className="container-judul-kartu">
            <div className="judulkartu">
              <div className="nama-rumah">Melody</div>
              <span>Ruko 3 Lantai</span>
            </div>
            <div className="cicilan">
              <div className="angka">17</div>
              <div className="ket">Juta/Bulan</div>
            </div>
          </div>
          <hr color="black" />
          <div className="gridspek">
            <FontAwesomeIcon color= '#334442' icon={faArrowsUpDownLeftRight} />
            <span> : 100m²</span>
            <FontAwesomeIcon color= '#334442' icon={faHouse} />
            <span> : 206m²</span>
            <FontAwesomeIcon color= '#334442' icon={faLayerGroup} />
            <span>: 3</span>
            <FontAwesomeIcon color= '#334442' icon={faShower} />
            <span>: 3</span>
          </div>
          <div className="containerwhatsapp">
            <button onClick={MelodyWa} className="whatsapp">
              <FontAwesomeIcon icon={faWhatsapp} /> Whatsapp
            </button>
          </div>
        </div>
        <div className="card">
        <Slider {...settings}>
            {Carsonimages.map((image, index) => (
              <img
                className="imagecard"
                key={index}
                src={image}
              />
            ))}
          </Slider>
          <div className="container-judul-kartu">
            <div className="judulkartu">
              <div className="nama-rumah">Carson</div>
              <span>Ruko 3 Lantai</span>
            </div>
            <div className="cicilan">
              <div className="angka">20</div>
              <div className="ket">Juta/Bulan</div>
            </div>
          </div>
          <hr color="black" />
          <div className="gridspek">
            <FontAwesomeIcon color= '#334442' icon={faArrowsUpDownLeftRight} />
            <span> : 80m²</span>
            <FontAwesomeIcon color= '#334442' icon={faHouse} />
            <span> : 186m²</span>
            <FontAwesomeIcon color= '#334442' icon={faLayerGroup} />
            <span>: 3</span>
            <FontAwesomeIcon color= '#334442' icon={faShower} />
            <span>: 3</span>
          </div>
          <div className="containerwhatsapp">
            <button onClick={CarsonWa} className="whatsapp">
              <FontAwesomeIcon icon={faWhatsapp} /> Whatsapp
            </button>
          </div>
        </div>
      </div>
      <div></div>
    </div>
  );
};

export default ruko1;
