import React from 'react'
import "./apartemen.scss"
import Apartemen1 from "./apartemen1/apartemen1.jsx"

const apartemen = () => {
  return (
    <div id='apartment' className='apartment'>
        <div className='container-header1'> Apartemen
        </div>
        <div>
            <div className='Judul'></div> 
            <Apartemen1/>
        </div>
    </div>
  )
}

export default apartemen;