import Melody1 from '../../../media/Ruko/Melody (1)/Melody (1).png'
import Melody2 from '../../../media/Ruko/Melody (1)/Melody (2).png'
import Melody3 from '../../../media/Ruko/Melody (1)/Melody (3).png'
import Melody4 from '../../../media/Ruko/Melody (1)/Melody (4).png'


const Melodyimages =[
Melody1,Melody2,Melody3,Melody4
]

export default Melodyimages;