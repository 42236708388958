import React from 'react'
import "./launchingcarson.scss"
import hardsellimage1 from "../../media/newlaunch-carson.png"
import background from "../../media/background-sms-flip.png"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckDouble } from "@fortawesome/free-solid-svg-icons";


const launchingcarson = () => {
    const handleConsultationClick = () => {
        const whatsappLink =
          "https://api.whatsapp.com/send/?phone=6285215127964&text=Halo%20Marketing%20Summarecon,%20Saya%C2%A0bisa%20minta%20detail%20Promo%20terbaru%20Summarecon%20https://marketingsummaserpong.com//&type=phone_number&app_absent=0";
        window.location.href = whatsappLink;
    }
  return (
    <div className="container-launchingcarson">
        <hr />
         <div id='launching' className="hardsellcarson">
            <div className="judul-hardsellcarson">
                <h1>New Launching</h1>
                <h2>Ruko Carson</h2>
            </div>
             <img className="hardsell-smscarson" src={hardsellimage1} alt="hardsell-summarecon-serpong" />
        </div>
        <div className="container-promocarson">
            <div className="judul-hardsellcarson">
                <h1>New Launching</h1>
                <h2>Ruko Carson</h2>
            </div>
            <hr />
            <div className="judul-promo-list-carson">
                <h1>Promo New Launching</h1>
                <div className="list-promocarson">
                    <div className="List promocarson">
                        <div className="penawaranpoincarson">
                            <FontAwesomeIcon icon={faCheckDouble} />
                            <span style={{ color: "white" }}>&nbsp;&nbsp;Insentif Usaha 100jt</span>
                        </div>
                        <div className="penawaranpoincarson">
                            <FontAwesomeIcon icon={faCheckDouble} />
                            <span style={{ color: "white" }}>&nbsp;&nbsp;Potensi Sewa 150jt/tahun</span>
                        </div>
                        <div className="penawaranpoincarson">
                            <FontAwesomeIcon icon={faCheckDouble} />
                            <span style={{ color: "white" }}>&nbsp;&nbsp;Direct Access and Double Line Parking</span>
                        </div>
                        <div className="penawaranpoincarson">
                            <FontAwesomeIcon icon={faCheckDouble} />
                            <span style={{ color: "white" }}>&nbsp;&nbsp;Free IPL 2 Tahun</span>
                        </div>
                        <div className="penawaranpoincarson">
                            <FontAwesomeIcon icon={faCheckDouble} />
                            <span style={{ color: "white" }}>&nbsp;&nbsp;Alfresco & Sky Balcony</span>
                        </div>
                    </div>
                </div>
                <div className="disclaimercarson">
                    <h1>*Disclaimer</h1>
                </div>
                <div className="button-penawarancarson"><button className="buttonpenawaran" onClick={handleConsultationClick}>
                     Request Consultation
                </button>
                </div>
            </div>
        </div>
    </div>
  )
}

export default launchingcarson;